import { Component, OnInit,HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  hidden: boolean;

  constructor(public _translate: TranslateService,) { }

  ngOnInit(): void {
  }

  hide() {
    this.hidden = true;
  }

}
