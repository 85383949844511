import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class Constant {
  currentUserTypes = JSON.parse(localStorage.getItem('CURRENT_USER'))?.userTypes;

  constructor() {
  }
  SERVICE_TYPE_CODE = {
    SHOP:'SHOP',
    FOOD:'FOOD'
  }
  SHOP = this.SERVICE_TYPE_CODE.SHOP;
  FOOD = this.SERVICE_TYPE_CODE.FOOD;

  REVISION_GROUP_CODES = {
    COLOR: 'color',
    SIZE: 'size'
  };
  DELIVERY_METHOD = {
    _My: 'MY',
    _BRANCH: 'BRANCH',
    _5DMA: '5DMA',
    _SCHEDULES: 'Scheduled'
  };


  pageSizeOptions = [5, 10, 15, 20];
  seachTimeOut = 1200;

  ROUND: number = 100;

  DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm:ss a';
  DATE_FORMAT = 'yyyy-MM-dd';
  format = 'hh:mm a';
  //  upload images codes
  IMAGE_FOOD_MENU_ITEM = 'IMAGE_FOOD_MENU_ITEM';
  IMAGE_USER_PROFILE = 'IMAGE_USER_PROFILE';
  IMAGE_INV_CATEGORY = 'IMAGE_INV_CATEGORY';
  IMAGE_INV_SUB_CATEGORY = 'IMAGE_INV_SUB_CATEGORY';
  IMAGE_INV_BRAND = 'IMAGE_INV_BRAND';
  IMAGE_INV_ITEM = 'IMAGE_INV_ITEM';
  IMAGE_SETUP_CUSTOMER_BRANCH_ATTACH = 'IMAGE_SETUP_CUSTOMER_BRANCH_ATTACH';
  IMAGE_INV_ITEM_ATTACH = 'IMAGE_INV_ITEM_ATTACH';
  IMAGE_SETUP_SERVICE = 'IMAGE_SETUP_SERVICE';
  IMAGE_SETUP_SERVICE_CATEGORY = 'IMAGE_SETUP_SERVICE_CATEGORY';
  IMAGE_SETUP_ACCOUNT_MANAGER = 'IMAGE_SETUP_ACCOUNT_MANAGER';
  IMAGE_SETUP_CUSTOMER_BRANCH = 'IMAGE_SETUP_CUSTOMER_BRANCH';
  IMAGE_ARTICLE_SMALL = 'IMAGE_ARTICLE_SMALL';
  IMAGE_ARTICLE_LARGE = 'IMAGE_ARTICLE_LARGE';
  IMAGE_ECOMMERCE_CONFIG = 'IMAGE_ECOMMERCE_CONFIG';
  IMAGE_ECOMMERCE_CONFIG_AD_MOBILE = 'IMAGE_ECOMMERCE_CONGIG_AD_MOBILE';
  IMAGE_ECOMMERCE_CONFIG_AD_WEB = 'IMAGE_ECOMMERCE_CONGIG_AD_WEB';
  CUSTOMER_INVOICE_BILLING_RECEIPT = 'CUSTOMER_INVOICE_BILLING_RECEIPT';
  PERIODS = [
    {bundleCode: 'DASHBOARD.PERIODS.DAY', value: 'DAY'},
    {bundleCode: 'DASHBOARD.PERIODS.WEEK', value: 'WEEK'},
    {bundleCode: 'DASHBOARD.PERIODS.MONTH', value: 'MONTH'},
    {bundleCode: 'DASHBOARD.PERIODS.YEAR', value: 'YEAR'}];

  SUB_PERIODS = [
    {bundleCode: 'SUB_PERIODS.MONTHLY', value: 'MONTHLY',id:2},
    {bundleCode: 'SUB_PERIODS.QUARTERLY', value: 'QUARTERLY',id:3},
    {bundleCode: 'SUB_PERIODS.SEMI_YEAR', value: 'SEMI_YEAR',id:4},
    {bundleCode: 'SUB_PERIODS.YEARLY', value: 'YEARLY',id:5}];

  ORDER_TYPES = [
    {bundleCode: 'DASHBOARD.ORDER_TYPE.EXTERNAL', value: 'EXTERNAL'},
    {bundleCode: 'DASHBOARD.ORDER_TYPE.INTERNAL', value: 'INTERNAL'}
  ];

  ACTIVE_ACCOUNTS = [
    {bundleCode: 'DASHBOARD.ACTIVE_ACCOUNTS.ALL_EMPLOYEES', value: '0'},
    {bundleCode: 'DASHBOARD.ACTIVE_ACCOUNTS.ACTIVE_EMPLOYEES', value: '1'}
  ];

  ACTUAL_EXPECTED = [{bundleCode: 'DASHBOARD.EXPECTED', value: 'EXPECTED'},
    {bundleCode: 'DASHBOARD.ACTUAL', value: 'ACTUAL'},];
  IMAGE_FOOD_MENU_CATEGORY = 'IMAGE_FOOD_MENU_CATEGORY';

  LOGIN_VEDIO = 'https://www.youtube.com/embed/094x-2Jz9og';
  STATUS_CODE = {
    'DRAFT': 1,
    'CONFIRMED': 2,
    'APPROVED': 3,
    'REJECTED': 4,
    'ACTIVE': 5,
    'INACTIVE': 6,
    'OPEN': 7,
    'CLOSED': 8,
  };
  DASHBOARD_STATUS_CODE = [
    {bundleCode: 'DASHBOARD.STATUS_CODE.ALL', key: 'all'},
    {bundleCode: 'DASHBOARD.STATUS_CODE.DRAFT', key: 'draft'},
    {bundleCode: 'DASHBOARD.STATUS_CODE.ACTIVE', key: 'active'},
    {bundleCode: 'DASHBOARD.STATUS_CODE.INACTIVE', key: 'inActive'},
    {bundleCode: 'DASHBOARD.STATUS_CODE.DUEINVOICE', key: 'dueInvoice'}];

  branchDayOfWorks: any[] = [
    {
      id: 1,
      active: false,
      dayOfWeek: {

        name: 'الأحد',
      }
    },
    {
      id: 2,
      active: false,
      dayOfWeek: {

        name: 'الاثنين',
      }
    },
    {
      id: 3,
      active: false,
      dayOfWeek: {

        name: 'الثلاثاء',
      }
    },
    {
      id: 4,
      active: false,
      dayOfWeek: {

        name: 'الاربع',
      }
    },
    {
      id: 5,
      active: false,
      dayOfWeek: {

        name: 'الخاميس',
      }
    },
    {
      id: 6,
      active: false,
      dayOfWeek: {

        name: 'الجمعه',
      }
    },
    {
      id: 7,
      active: false,
      dayOfWeek: {

        name: 'السبت',
      }
    },
  ];

  zoomMap: 16;
  userTypes = {
    ADMIN: 'ADMIN',
    CUST: 'CUST',
    CONS: 'CONS',
    ACCM: 'ACCM',
    EMP: 'EMP',
    SUPP: 'SUPPLIER',
    BACKOF: 'BACKOF',
    SUPERVISOR: 'SUPERVISOR'
  };

  // invoiceStatus
  invoiceStatus = {
    ENTERED: 'ENTERED',
    CONFIRMED: 'CONFIRMED',
    INSTALL: 'INSTALL',
    POSTED: 'POSTED',
    PAID: 'PAID',
    CANCELED: 'CANCELED',
  };

  payrollStatus = {
    DRAFT: 'DRAFT',
    CALCULATED: 'CALCULATED',
    ACCRUAL_POSTED: 'ACCRUAL_POSTED',
    PAID: 'PAID',
    PAYMENT_POSTED: 'PAYMENT_POSTED',
    CANCELED:'CANCELED'
  };

  // journalSource
  JOURNAL_SOURCE = {
    MANUAL: 'MANUAL',
    INV: 'INV',
    SALES: 'SALES'
  };
  // journalSource
  JOURNAL_AMOUNT_TYPE = {
    CREADIT: 'credit',
    DEBIT: 'debit',
  };

  JOURNAL_STATUS = {
    ENTERED: 'ENTERED',
    CONFIRMED: 'CONFIRMED',
    POSTED: 'POSTED',
    REVERSED: 'REVERSED'
  };

  CONSUMER_TYPE = {
    COMPANY: 'COMPANY',
    INDIVIDUAL: 'INDIVIDUAL'
  };


  OFFER_TYPE = {
    SPECIAL_PRICE: 'SPECIAL_PRICE',
    ITEM_GROUP: 'ITEM_GROUP',
    TARGET: 'TARGET',
    GIFT: 'GIFT',
    FREE_DELIVERY: 'FREE_DELIVERY',
    POINS_OFFER: 'POINS_OFFER',
    PROMO_CODE: 'PROMO_CODE'
  };
  getTaxPercentage() {
    let taxPercentage = JSON.parse(localStorage.getItem('SessionData'))?.taxPercentage / 100;

    return taxPercentage ? taxPercentage : .15;
  }

  userIsAdmin() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.ADMIN) ? true : false;
  }

  userIsCustomer() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.CUST) ? true : false;
  }

  userIsConsumer() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.CONS) ? true : false;
  }

  userIsAccManager() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.ACCM) ? true : false;
  }

  userIsEmployee() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.EMP) ? true : false;
  }

  userIsSupervisor() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.SUPERVISOR) ? true : false;
  }

  userIsBackOffice() {
    return this.currentUserTypes?.find(type => type.code === this.userTypes.BACKOF) ? true : false;
  }

  notificationTypes = {
    ORDER: 'ORDER',
    SCHEDUL: 'SCHEDUL',
    RETURN: 'RETURN',
    FOOD_ORDER: 'FOOD_ORDER',
    FOOD_SCHEDUL: 'FOOD_SCHEDUL',
    FOOD_RETURN: 'FOOD_RETURN',
    CUSTOMER_INVOICE: 'CUSTOMER_INVOICE',
    TRANSFER: 'TRANSFER',
    SHIFT_EXTENSION_APPROVAL: 'SHIFT_EXTENSION_APPROVAL',
    SHIFT_EXTENSION_REQUESTER: 'SHIFT_EXTENSION_REQUESTER'
  };
  //
  // sales report types Order, Order Details, Summery
  SALES_REPORT_TYPES = [
    {code: 'SALES.REPORT_TYPE.ORDER', value: 'SalesFullReport', ORDER: 'ORDER'},
    {code: 'SALES.REPORT_TYPE.ORDER_DETAILS', value: 'DetailSalesReport', DETAILS: 'ORDER_DET'},
    {code: 'SALES.REPORT_TYPE.SUMMARY', value: 'SummarySalesReport', SUMMARY: 'SUMMARY'},
    {code: 'SALES.REPORT_TYPE.DETAILS_BRAND', value: 'DetailedBrandReport', DETAILS_BRAND: 'DetailedBrandReport'},
    {code: 'SALES.REPORT_TYPE.POS_SESSION', value: 'pos_session', POS_SESSION: 'pos_session'},
  ];
  PURCHASING_REPORT_TYPES = [
    {code: 'PURCHASING.REPORT_TYPE.PURCHASE', value: 'PurchasingMasterDataReport'},
    {code: 'PURCHASING.REPORT_TYPE.FULL', value: 'PurchasingFullReport'},
    {code: 'PURCHASING.REPORT_TYPE.SUMMARY', value: 'SummaryPurchasingReport'},
    {code: 'PURCHASING.REPORT_TYPE.INVENTORY', value: 'InventoryQuantityReport'},
    {code: 'PURCHASING.REPORT_TYPE.SUPPLIER_STATEMENT', value: 'supplier_statement'},
    {code: 'PURCHASING.REPORT_TYPE.SUPPLIER_AGING', value: 'supplier_aging'},
    {code: 'PURCHASING.REPORT_TYPE.SUPPLIER_DUE_PAYMENTS', value: 'supplier_due_payments'},
  ];
  SALES_REPORT = {
    ORDER: 'SalesFullReport',
    DETAILS: 'DetailSalesReport',
    SUMMARY: 'SummarySalesReport',
    DETAILS_BRAND: 'DetailedBrandReport',
    POS_SESSION: 'pos_session',
  };
  PURCHASING_REPORT = {
    PURCHASE: 'PurchasingMasterDataReport',
    FULL: 'PurchasingFullReport',
    SUMMARY: 'SummaryPurchasingReport',
    INVENTORY: 'InventoryQuantityReport',
    SUPPLIER_STATEMENT: 'supplier_statement',
    SUPPLIER_AGING: 'supplier_aging',
    SUPPLIER_DUE_PAYMENTS: 'supplier_due_payments',
  };

  FINANCE_REPORT = {
    EXPENSES: 'ExpensesReport',
    REVENUE: 'RevenueReport',
    TRIAL_BALANCE: 'fin_TrialBalance',
    INCOME_STATEMNT: 'fin_IncomeStatement',
    BALANCE_SHEET: 'fin_BalanceSheet',
  };
  FINANCE_REPORT_TYPES = [
    {code: 'FINANCE.REPORT_TYPE.EXPENSES', value: 'ExpensesReport', EXPENSES: 'ExpensesReport'},
    {code: 'FINANCE.REPORT_TYPE.REVENUE', value: 'RevenueReport', REVENUE: 'RevenueReport'},
    {code: 'FINANCE.REPORT_TYPE.TRIAL_BALANCE', value: 'fin_TrialBalance', TRIAL_BALANCE: 'fin_TrialBalance'},
    {code: 'FINANCE.REPORT_TYPE.INCOME_STATEMNT', value: 'fin_IncomeStatement', INCOME_STATEMNT: 'fin_IncomeStatement'},
    {code: 'FINANCE.REPORT_TYPE.BALANCE_SHEET', value: 'fin_BalanceSheet', BALANCE_SHEET: 'fin_BalanceSheet'},
  ];

  REPORT_PERIOD_TYPES = [
    {code: 'DASHBOARD.PERIODS.WEEKLY', value: 'WEEKLY'},
    {code: 'DASHBOARD.PERIODS.MONTHLY', value: 'MONTHLY'},
  ];

  REPORT_PERIOD = {
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY'
  };

  REFERRAL_TYPE = [
    {id: 1, bundleCode: 'REERRAL.SALES_SPECIALIST_PHONE'},
    {id: 2, bundleCode: 'REERRAL.CUST_PHONE'},
  ];

  LANG_TYPES = [
    {code: 'ORDER_VIEW.AR', value: 'ar'},
    {code: 'ORDER_VIEW.EN', value: 'en'},
    {code: 'ORDER_VIEW.FR', value: 'fr'},
  ];
  LANG_TYPE = {
    AR: 'ar',
    EN: 'en',
    FR: 'fr',
  };
  PAYMENT_WAYS = {
    CASH: 'CASH',
    ONLINE: 'ONLINE',
  };
  minutes = [
    {id: 30, value: 30},
    {id: 60, value: 60},
    {id: 90, value: 90},
    {id: 120, value: 120},
    {id: 150, value: 150},
    {id: 180, value: 180},
    {id: 210, value: 210},
    {id: 240, value: 240},
  ];

  POS_INVOICE_TYPE = {
    POS: 'POS',
    TAX: 'TAX'
  };

  FOOD_ORDER_TYPE = {
    INSIDE: 'INSIDE',
    READY: 'READY',
    DELIVERY: 'DELIVERY'
  };
  PAYMENT_TERM = {
    Immediate: 'IMD',
    End_of_month: 'EOM',
    Cash_on_delivery: 'COD',
    Cash_next_delivery: 'CND',
    Net10: 'N10',
    Net15: 'N15',
    Net30: 'N30',
    Net45: 'N45',
    Net60: 'N60',
    Net90: 'N90'
  };

  PAYABLE_INVOICE_TYPES = {
    PREPAYMENT: 'PREPAYMENT',
    STANDARD: 'STANDARD',
    CREDIT_NOTE: 'CREDIT-NOTE'
  };

  /**
   * format date with format yyyy-MM-dd HH:mm:ss
   * @param date
   */
  formatDate(date: any) {
    return new Date(date)
      .toISOString()
      .replace(/T/, ' ') // replace T with a space
      .replace(/\..+/, '');
  }


}


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'YYYY MM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM'
  },
};

