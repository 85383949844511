import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NgxImageCompressService} from 'ngx-image-compress';
import {Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {BaseService} from '../../service/base.service';
import {ImageDialogComponent} from '../image-dialog/image-dialog.component';
import {SnackService} from '../snack/snack.service';

import {ImageDialogProcessingComponent} from './image-dialog-processing/image-dialog-processing.component';

@Component({
  selector: 'attaches-files-component',
  templateUrl: './attaches-files.component.html',
  styleUrls: ['./attaches-files.component.scss'],
})
export class AttachesFilesComponent implements OnInit {
  @Input() multiple_upload: boolean = false;
  @Input() uploadId: string;
  @Input() disabled: boolean = true;
  @Input() id: number;
  @Input() filesAttachments: any[] = [];
  @Input() uploadButton: boolean = true;
  @Input() deletItemApi: string = '';
  @Input() attachesApi: string = '';
  @Input() propertyName: string = '';
  @Input() avatar_img: string = '';
  @Input() upload_Avatar = new Subject<undefined>();
  @Output() imgPathResult: EventEmitter<any> = new EventEmitter<any>();
  @Output() hasImg: EventEmitter<any> = new EventEmitter<any>();
  selectFiles: any = [];
  attachments: any[] = [];
  imgUrl = environment.baseImageUrl;
  avatarImg: string = '';
  showWebcam: boolean = false;
  compress: boolean = true;

  constructor(private translate: TranslateService, private base: BaseService, private dialog: MatDialog
    , private snackService: SnackService, private imageCompress: NgxImageCompressService) {
  }

  ngOnInit() {
    if (this.filesAttachments.length != 0) {
      this.attachments = this.filesAttachments;
    }

    // latest snapshot

    this.avatarImg = this.avatar_img;

    this.upload_Avatar.subscribe((id) => {
      if (id) {
        this.id = id;
      }
      if (this.selectFiles?.type) {
        this.uploadAvater();
      }

    });
  }

  showImageDialog(imagePath) {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      // width: '200px',
      data: {imagePath: imagePath}
    });
    dialogRef.afterClosed().subscribe((res) => {

    });
  }

  onSelectAvatarFile(event: any, ele: HTMLInputElement) {
    const inputFiles = event.target.files;
    if (!this.validateFile(inputFiles[0].name)) {
      this.snackService.showSnack('COMMON.VALIDATIONS.NOT_SUPPORTED','error')
      return;
    }else {
      const dialogRef = this.dialog.open(ImageDialogProcessingComponent, {
        data: {event: event, type: 'file'},
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          if (res.img) {
            let file = this.dataURLtoFile(res.img, '');
            if (res.compress) {
              this.fileCanBeCompressed(file);
            } else {
              this.avatarImg = res.img;
              this.selectFiles = file;
            }
          } else {
            const files: FileList = event.target.files;
            if (res.compress) {
              this.fileCanBeCompressed(files[0]);
            } else {
              this.imgToBase64(files[0], (img) => {
                this.avatarImg = img;
              });
              this.selectFiles = files[0];
            }
          }
        } else {
          ele.value = '';
        }
      });
    }
  }
  validateFile(name: String) {
    let ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg') {
      return true;
    }
    else {
      return false;
    }
  }
  fileCanBeCompressed(file) {
    this.imgToBase64(file, (img) => {
      this.avatarImg = img;
    });

    this.compressFile(file).then(img => {
      if (file) {
        this.hasImg.emit(true)
        this.selectFiles = img;
        // this.uploadAvater();
      }
    });
  }

  onSelectFileChange(event: any) {
    let files: FileList = event.target.files;
    let dt = new DataTransfer();
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let ext = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg') {
        dt.items.add(file);
      }
    }
    files = dt.files;
    if (files.length > 0) {
      this.selectFiles = files;
    }
    this.compress = true;
  }

  uploadAvater() {
    this.base.uploadToGoogle(this.id, this.uploadId, this.selectFiles).subscribe(file => {
      this.avatarImg = file.data.fileDownloadUri;
      this.imgPathResult.emit(file.data.imagePath)
      this.selectFiles = null;
    });
  }

  uploadFiles() {
    if (this.selectFiles.length == 0) {
      return;
    }
    const attaches = [];
    const files = Object.values(this.selectFiles);

    files.forEach(file => {
      attaches.push({
        id: null,
        imagePath: null,
        [this.propertyName]: this.id,
      });
    });
    this.base.post(attaches, this.attachesApi).subscribe(res => {
      const uploadItems = [];
      res.data.forEach((attachItem, index) => {
        uploadItems.push({id: attachItem.id, file: files[index]});
      });
      for (const uploadItem of uploadItems) {
        if (this.compress) {
          this.compressFile(uploadItem.file).then((imgFile: File) => {
            this.base.uploadToGoogle(uploadItem.id, this.uploadId, imgFile).subscribe((res) => {
              this.attachments.push(res.data);
            });
          });
        } else {
          this.base.uploadToGoogle(uploadItem.id, this.uploadId, uploadItem.file).subscribe((res) => {
            this.attachments.push(res.data);
          });
        }
      }
      this.selectFiles = [];
      this.snackService.showSnack(this.translate.instant('COMMON.SUCCESS_UPLOAD'), 'success');
    });
  }

  deleteItemAttach(itemAttach) {
    this.base.deleteById(`${this.deletItemApi}?id=${itemAttach.relatedId || itemAttach.id}`).subscribe(res => {
      this.attachments.splice(this.attachments.indexOf(itemAttach), 1);
      this.snackService.showSnack('COMMON.DELETE_SUCCESS', 'success');
    });
  }

  compressFile(imageFile) {
    return new Promise((resolve, reject) => {
      this.imageCompress.getOrientation(imageFile).then(orientation => {
        this.imgToBase64(imageFile, base64 => {
          this.imageCompress.compressFile(base64, orientation, 50, 40).then(result => {
            resolve(this.base64ToFile(result, imageFile.name));
          });
        });
      });
    });
  }

  imgToBase64(img, callback) {
    const reader = new FileReader();
    if (img) {
      reader.readAsDataURL(img);
    }
    reader.addEventListener('load', function() {

      callback(reader.result);
    }, false);
  }

  base64ToFile(base64, filename) {
    var arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  openCameraDialog() {
    const dialogRef = this.dialog.open(ImageDialogProcessingComponent, {
      data: {type: 'camera'}
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (res.img) {
          let file = this.dataURLtoFile(res.img, '');
          if (this.multiple_upload) {
            if (res.compress) {
              this.compress = true;
              this.selectFiles = [file];
            } else {
              this.selectFiles = [file];
              this.compress = false;
            }
          } else {
            if (res.compress) {
              this.fileCanBeCompressed(file);
            } else {
              this.avatarImg = res.img;
              this.selectFiles = file;
            }
          }
        }
      }
    });
  }

  dataURLtoFile(dataurl, filename) {

    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
