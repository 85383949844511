export const environment = {
  production: false,
  headerStyle:{
    'background-color':'#f08080',
    'color':'#dfe3e4'
  },
  prodHeaderStyle: {
    'background-color': '#FFFFFF',
    'color': '#e44891'
  },
  billIconColor: '#ffffff',
  apiUrl: 'https://5dmauat.online/service-5dma/',
  baseImageUrl: 'https://khdma-bucket.s3.eu-west-2.amazonaws.com/',
  appToken : 'PORTAL_OF5a6P1iKbVDdrIe5OOvkGxYZo6XT',
  googleTranslateApiKey : 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyD8SgJXnz7LzQAhk02uyQbV-BtnE2ahhc8' ,
  firebase : {
    apiKey: "AIzaSyD8SgJXnz7LzQAhk02uyQbV-BtnE2ahhc8",
    authDomain: "dmaapp-56741.firebaseapp.com",
    databaseURL: "https://dmaapp-56741.firebaseio.com",
    projectId: "dmaapp-56741",
    storageBucket: "dmaapp-56741.appspot.com",
    messagingSenderId: "360289453312",
    appId: "1:360289453312:web:fff38dcc00791f409f1fe5",
    measurementId: "G-Y4QEJ0ZVXV"
  }
};
