<div *ngIf="!hidden" style="width: 96%" [dir]="_translate.currentLang == 'ar' ? 'rtl' : 'ltr'"
  class="alert alert-danger row" role="alert">
  <p class="col-sm-7 col-md-10">{{'PRINTER.ERROR_MSG' | translate}}</p>
  <div class="row col-sm-5 col-md-2">
    <div class="col-sm-6" style="padding-right: 0.25rem;padding-left: 0.25rem">
      <button style="width: 100%" color="primary" mat-raised-button>{{'COMMON.FIX' | translate}}</button>
    </div>
    <div class="col-sm-6" style="padding-right: 0.25rem;padding-left: 0.25rem">
      <button style="width: 100%" mat-stroked-button (click)="hide()">{{'COMMON.HIDE' | translate}}</button>
    </div>
  </div>
</div>
