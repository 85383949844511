
<app-loader *ngIf="loader.showLoader"></app-loader>

<div [dir]="direction" style="height: 100%;">
  <router-outlet></router-outlet>
</div>
<tour-step-template>
  <ng-template let-step="step">
    <mat-card
      [dir]="direction"
      style="box-shadow: none;width: fit-content"
      (click)="$event.stopPropagation()"


    >
      <mat-card-header>
        <h3>
            {{ step.title | translate}}
        </h3>

      </mat-card-header>

      <mat-card-content
        class="mat-body"
        [innerHTML]="step.content |translate"
      ></mat-card-content>

      <mat-card-actions
        [class.no-progress]="!step.showProgress"
      >
        <button
          mat-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          {{ step.prevBtnTitle |translate}}
        </button>
        <ng-template [ngIf]="step.showProgress">
          <div class="progress">{{ tourService.steps?.indexOf(step) + 1 }} / {{ tourService.steps?.length }}</div>
        </ng-template>

        <ng-template [ngIf]="tourService.hasNext(step) && !step.nextOnAnchorClick">
          <button
            class="next"
            (click)="tourService.next()"
            mat-button
          >
            {{ step.nextBtnTitle |translate}}
          </button>        </ng-template>
        <ng-template [ngIf]="!tourService.hasNext(step)">
          <button
            mat-button
            (click)="end()"
          >
            {{ step.endBtnTitle |translate}}
          </button>       </ng-template>

      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
